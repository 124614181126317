import _dom from "./dom";
import _domParser from "./dom-parser";
var exports = {};
var dom = _dom;
exports.DOMImplementation = dom.DOMImplementation;
exports.XMLSerializer = dom.XMLSerializer;
exports.DOMParser = _domParser.DOMParser;
export default exports;
export const DOMImplementation = exports.DOMImplementation,
      XMLSerializer = exports.XMLSerializer,
      DOMParser = exports.DOMParser;